<!-- 新版 鹰视首页 -->
<template>
  <div class="fierce_look">
    <div class="left_btn" @click="left_btn" @mouseout="deviate">
      <div>
      <img src="@/assets/new_yingshi/leftBtn.png" />
      </div>
    </div>
    <div class="right_btn" @click="right_btn" @mouseout="deviate">
      <div>
      <img src="@/assets/new_yingshi/leftBtn.png" />
      </div>
    </div>
    <div class="zhong"></div>
    <div class="three3d">
      <div class="weizhi">
        <div class="content" :style="{ transform: card_style }">
          <span class="out_left" @mousemove="cardOne" @mouseout="quit" @click="openBlank('#/fckernelVideos')">
            <div class="bj_icon videos">
            </div>
            <div class="card-count">
              <span>总数: {{uavOnline + uavOffline}}</span>
              <span class="card-count__online">在线: {{uavOnline}}</span>
            </div>
            <img src="~@/assets/new_yingshi/shiPinQiang.png" class="card__label" />
          </span>
          <span class="out_before" @mousemove="cardTwo" @mouseout="quit" @click="openBlank('#/fckernel')">
            <div class="bj_icon uav">
            </div>
            <div class="card-count">
              <span>总数: {{uavOnline + uavOffline}}</span>
              <span class="card-count__online">在线: {{uavOnline}}</span>
            </div>
            <img src="~@/assets/new_yingshi/wuRenJiYingYong.png" class="card__label" />
          </span>
          <span class="out_after" @mousemove="cardThree" @mouseout="quit" @click="openBlank('#/fckernel?page=2')">
            <div class="bj_icon yingchao">
            </div>
            <div class="card-count">
              <span>总数: {{nestOnline + nestOffline}}</span>
              <span class="card-count__online">在线: {{nestOnline}}</span>
            </div>
            <img src="~@/assets/new_yingshi/yingChaoYingYong.png" class="card__label" />
          </span>
          <div class="qiu" :style="{ transform: earth_style }">
            <div class="before">
              <img
                src="~@/assets/new_yingshi/shouye_diqiu.png"
                style="width: 100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pa fierce_look_v1 cp" @click="tolink(1)"></div>
  </div>
</template>
  
<script>
// 顶部
import API from "@/api"
export default {
  components: {
  },
  data() {
    return {
      card_angle: 360, //控制外围卡片旋转 旋转度数
      card_style: null, // 外围卡片旋转样式(css)
      card_time: null, // 外围卡片旋转定时器
      earth_angle: 0, // 控制内部地球旋转    旋转度数
      earth_style: null, // 内部地球旋转样式(css)
      earth_time: null, // 内部地球旋转定时器
      stop_time: null, // 旋转暂停定时器
      timeOne: null, //卡片移动到正面的定时器
      timeTwo: null,
      timeThree: null,
      save: null, //保存要旋转的角度
      takeOff: true, //误判锁
      clicktake: false,
      leftTake: true,
      rightTake: true,

      uavOnline: 0,
      uavOffline: 0,
      nestOnline: 0,
      nestOffline: 0,

      startedTurn: false,     //已经开始旋转
    };
  },
  created(){
    this.$store.commit('mapmanage/SET_VISIBLE_MAIN_MAP', false);
  },
  mounted() {
    document.title = "鹰视";
    this.turn();
    // API.FCKERNEL.ListUAV({}).then(res => {
    API.DEVICE.List({cate:1}).then(res => {
      if(res.length > 0){
        this.uavOnline = res[0].online;
        this.uavOffline = res[0].offline;
      }
    })

    API.NEST.getNestList().then(res => {
      this.nestOnline = res.onLineCount;
      this.nestOffline = res.offLineCount;
    })
  },
  methods: {
    tolink(){
      this.$router.push({ path: "/observe/home/2" });
    },
    openBlank(url){
      window.open(url, '_blank');
    },
    left_btn() {
      console.log('left_btn')
      if (!this.startedTurn){
        return;
      }
      if (this.leftTake == false) {
        return;
      }
      this.rightTake = false;
      this.leftTake = false;
      this.close_time();
      this.clicktake = true;
      if (this.card_angle < 71) {
        this.save = 338;
        this.fahrbereit();
        this.close_time();
        return;
      } else if (this.card_angle == 71) {
        this.save = 338;
        this.fahrbereit();
        this.close_time();
        return;
      }
      if (this.card_angle < 205) {
        this.save = 71;
        this.fahrbereit();
        this.close_time();
        return;
      } else if (this.card_angle == 205) {
        this.save = 71;
        this.fahrbereit();
        this.close_time();
        return;
      }
      if (this.card_angle < 338) {
        this.save = 205;
        this.fahrbereit();
        this.close_time();
        return;
      } else if (this.card_angle == 338) {
        this.save = 205;
        this.fahrbereit();
        this.close_time();
        return;
      }else{
        this.rightTake = true;
        this.leftTake = true;
      }
    },
    right_btn() {
      console.log('right_btn');
      if (!this.startedTurn){
        return;
      }
      if (this.rightTake == false) {
        return;
      }
      this.close_time();
      this.rightTake = false;
      this.leftTake = false;
      this.clicktake = true;
      if (this.card_angle < 71) {
        this.save = 71;
        this.fahrbereit_against();
        this.close_time();
        return;
      } else if (this.card_angle == 71) {
        this.save = 205;
        this.fahrbereit_against();
        this.close_time();
        return;
      }
      if (this.card_angle < 205) {
        this.save = 205;
        this.fahrbereit_against();
        this.close_time();
        return;
      } else if (this.card_angle == 205) {
        this.save = 338;
        this.fahrbereit_against();
        this.close_time();
        return;
      }
      if (this.card_angle < 338) {
        this.save = 338;
        this.fahrbereit_against();
        this.close_time();
        return;
      } else if (this.card_angle == 338) {
        this.save = 71;
        this.fahrbereit_against();
        this.close_time();
        return;
      }else{
        this.rightTake = true;
        this.leftTake = true;
      }
    },
    // 控制卡片一鼠标悬浮效果
    cardOne() {
      // 首先执行暂停方法
      this.close_time();
    },
    cardTwo() {
      // 首先执行暂停方法
      this.close_time();
    },
    cardThree() {
      this.close_time();
    },
    // 将卡片旋转到正面的方法
    fahrbereit() {
      clearTimeout(this.timeOne);
      this.timeOne = null;
      // 开启一次性定时器
      this.timeOne = setTimeout(() => {
        // 判断当前角度是否不等于预设的角度值
        if (this.card_angle != this.save) {
          // console.log(this.card_angle, this.save);
          //  旋转
          this.card_angle = this.card_angle - 1;
          if (this.card_angle == 0) {
            this.card_angle = 360;
          }
          let a = this.card_angle;
          this.card_style = `rotateY(${a}deg)`;

          this.earth_angle++;
          if (this.earth_angle == 360) {
            this.earth_angle = 0;
          }
          let b = this.earth_angle;
          this.earth_style = `rotateY(${b}deg)`;
          // 如果不等于预设角度值重复调用
          this.fahrbereit();
        } else {
          // 如果等于则关闭定时器

          this.close_time();
          this.leftTake = true;
          this.clicktake = true;
          this.rightTake = true;
          return;
        }
      }, 5);
    },
    // 将卡片旋转到正面的方法(反)
    fahrbereit_against() {
      clearTimeout(this.timeTwo);
      this.timeTwo = null;
      // 开启一次性定时器
      this.timeTwo = setTimeout(() => {
        // 判断当前角度是否不等于预设的角度值
        if (this.card_angle != this.save) {
          //   console.log(this.card_angle, this.save);
          //  旋转
          this.card_angle++;
          if (this.card_angle == 360) {
            this.card_angle = 0;
          }
          let a = this.card_angle;
          this.card_style = `rotateY(${a}deg)`;

          this.earth_angle = this.earth_angle - 1;
          if (this.earth_angle == 0) {
            this.earth_angle = 360;
          }
          let b = this.earth_angle;
          this.earth_style = `rotateY(${b}deg)`;
          // 如果不等于预设角度值重复调用
          this.fahrbereit_against();
        } else {
          // 如果等于则关闭定时器;
          this.close_time();
          this.rightTake = true;
          this.clicktake = true;
          this.leftTake = true;
          return;
        }
      }, 5);
    },
    // 鼠标离开
    quit() {
      this.turn();
    },
    deviate() {
      if (this.clicktake == false) {
        return;
      }
      if (this.takeOff == false) {
        return;
      }
      this.takeOff = false;
      clearTimeout(this.timeThree);
      this.timeThree = null;
      this.timeThree = setTimeout(() => {
        this.turn();
        this.takeOff = true;
        this.clicktake = false;
        clearTimeout(this.timeThree);
        this.timeThree = null;
      }, 3000);
    },
    // 旋转动画控制
    turn() {
      this.close_time();
      // 外部卡片样式正转
      this.card_time = setInterval(() => {
        // console.log("888");
        this.card_angle = this.card_angle - 1;
        if (this.card_angle == 0) {
          this.card_angle = 360;
        }
        let zorn = this.card_angle;
        this.card_style = `rotateY(${zorn}deg)`;
      }, 50); // 控制旋转速度
      // 内部地球反转(这样内部可以相对静止)
      this.earth_time = setInterval(() => {
        this.earth_angle++;
        if (this.earth_angle == 360) {
          this.earth_angle = 0;
        }
        let zorn = this.earth_angle;
        this.earth_style = `rotateY(${zorn}deg)`;
      }, 50); // 控制旋转速度

      setTimeout(() => {
        this.startedTurn = true;
      },2000);      //初始化后2秒才允许响应左右点击事件
    },
    // 关闭定时器
    close_time() {
      clearInterval(this.card_time);
      clearInterval(this.earth_time);
      this.card_time = null;
      this.earth_time = null;
      // this.startedTurn = false;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.fierce_look {
  box-sizing: border-box;
  padding-top: 104px;
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  position: fixed;
  left: 0;
  top: 0;

  .fierce_look_v1 {
  width: 150px;
  height: 50px;
  z-index: 90;
  bottom: 10px;
  right: 20px;
  background-image: url("~@/assets/img/homeChange1.png");
  background-size: contain;
  background-repeat: no-repeat;
}

}
.three3d {
  width: 100%;
  height: calc(100% - 104px);
  position: relative;
}
.weizhi {
  position: absolute;
  left: 34%;
  top: 3%;
  background-color: transparent;
  transform-style: preserve-3d;
  perspective: 3500px;

  // transform: rotateX(87deg);
}
/*盒子*/
.content {
  margin: 200px;
  width: 200px;
  height: 200px;
  position: relative;
  transform: perspective(400px);
  transform-style: preserve-3d;
  background: transparent;
  transform-origin: center, center;
//   transform: rotateX();
}

/*内层正方体*/
.qiu {
  width: 150px;
  height: 100px;
  //   transform-style: preserve-3d;
  //   background-color: transparent;
  position: absolute;
  left: 25px;
  bottom: calc(100% + 250px);
}

.content >span {
  width: 278px;
  height: 335px;
  position: absolute;
  color: #fff;
  text-align: center;
  line-height: 200px;
  transition: all 1s;
  display: inline-block;
  box-sizing: border-box;
  padding: 47px 0 0 0;
  display: flex;
  justify-content: center;
}
.bj_icon {
  width: 200px;
  height: 150px;
}
.videos {
  background-image: url("~@/assets/new_yingshi/yingshi.png");
  background-size: 100% 100%;
}
.uav {
  background-image: url("~@/assets/new_yingshi/uav.png");
  background-size: 100% 100%;
}
.yingchao {
  background-image: url("~@/assets/new_yingshi/yingchao.png");
  background-size: 100% 100%;
}
.before {
  transform: translateZ(50px);
  width: 1000px;
  position: fixed;
  left: -382px;
  height: 1000px;
  //   transform-origin:center,top;
}

/* 外层 */
.out_left {
  position: relative;
  transform: translateX(-650px) rotateY(270deg) rotateX(3deg) translateX(200px);
  background-image: url("~@/assets/new_yingshi/card.png");
  background-size: 100% 100%;
  cursor: pointer;
}

.out_before {
  transform: translateZ(650px) rotateY(21deg) rotateX(3deg) translateX(200px);
  background-image: url("~@/assets/new_yingshi/card.png");
  background-size: 100% 100%;
  cursor: pointer;
}

.out_after {
  transform: translateZ(-650px) rotateY(160deg) rotateX(3deg) translateX(-200px);
  background-image: url("~@/assets/new_yingshi/card.png");
  background-size: 100% 100%;
}
.left_btn {
  position: fixed;
  // left: 5%;
  top: 104px;
  width: 650px;
  height: 700px;
  //   background: #000;
  z-index: 10;
  display: flex;
  justify-content: start;
  align-items: center;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 156px;
    height: 182px;
    background: rgba(3,10,36,0.5);
    img{
      opacity: 0.6;
    }
      &:hover{
        img{
          opacity: 1;
        }
      }
  }
}
.right_btn {
  position: fixed;
  right: 0%;
  top: 104px;
  width: 542px;
  height: 700px;
  //   background: #000;
  z-index: 10;
  display: flex;
  justify-content: end;
  align-items: center;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 156px;
    height: 182px;
    background: rgba(3,10,36,0.5);
    img{
      transform: rotate(180deg);
      opacity: 0.6;
    }
      &:hover{
        img{
          opacity: 1;
        }
      }
  }
}
// .zhong{
//     position: fixed;
//   left: 45%;
//   top: 104px;
//   width: 332px;
//   height: 800px;
// //   background: #000;
//   z-index: 10;
// //   pointer-events: none;
// }
.mu{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: .3;
}

.card__label{
  position: absolute;
  left: 50%;
  bottom: 58px;
  transform: translate(-50%, 0);
  height: 36px;
}

.card-count{
  font-family: YouSheBiaoTiHei;
  position: absolute;
  top: 215px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 16px;
  display: flex;
  line-height: 16px;
  white-space: nowrap;
}

.card-count__online{
  margin-left: 30px;
  color: #14FAFF;
  background-image:-webkit-linear-gradient(bottom,red,#fd8403,yellow); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  .gradient-text{text-align: left;text-indent:30px;line-height: 50px;font-size:40px;font-weight:bolder; position: relative; }
}

.tabs{
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;

  .tab-item{
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #C7EBFF;
    line-height: 19px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:last-child{
      margin-right: 0;
    }

    &:hover {
      color: rgba(255, 234, 0, 1);

      .tab-icon {
        background-image: url(~@/assets/images/webhome/yk/yellow.png);

        .tab-icon__video {
          background-image: url(~@/assets/ying_ji_tabs/video_active.svg);
        }

        .tab-icon__camera{
          background-image: url(~@/assets/ying_ji_tabs/camera_active.svg);
        }

        .tab-icon__uav {
          background-image: url(~@/assets/ying_ji_tabs/uav_active.svg);
        }

        .tab-icon__nest{
          background-image: url(~@/assets/ying_ji_tabs/nest_active.svg);
        }
      }
    }

    .tab-icon{
      width: 51px;
      height: 48px;
      background-image: url(~@/assets/images/webhome/yk/blue.png);
      background-size: 100% 100%;
      margin-bottom: 3px;
      display: flex;
      align-items: center;
      justify-content: center;

      .tab-icon__video {
        width: 18px;
        height: 18px;
        background-image: url(~@/assets/ying_ji_tabs/video.svg);
        background-size: 100% 100%;
      }

      .tab-icon__camera{
        width: 18px;
        height: 18px;
        background-image: url(~@/assets/ying_ji_tabs/camera.svg);
        background-size: 100% 100%;
      }

      .tab-icon__uav {
        width: 18px;
        height: 18px;
        background-image: url(~@/assets/ying_ji_tabs/uav.svg);
        background-size: 100% 100%;
      }

      .tab-icon__nest{
        width: 18px;
        height: 18px;
        background-image: url(~@/assets/ying_ji_tabs/nest.svg);
        background-size: 100% 100%;
      }
    }
  }
}

</style>
    